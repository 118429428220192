$globalMargin: 25px;

// Font-sizes
$smallText: 0.9rem;
$largeText: 1.2rem;

$smallHeader: 1rem;
$mediumHeader: 1.2rem;
$largeHeader: 2rem;

$default: 1rem;
$largeIcon: 2rem;
$smallIcon: 1.2rem;

// Dark Mode
// Content Colors
$mainBackground: rgb(12, 12, 12);
$secondaryBackground: rgb(158, 158, 158);
$black: rgb(0, 0, 0);
$shadowColor: rgba(0, 0, 0, 0.445);

//Text Colors
$mainTextColor: #f5f5f5;
$secondaryTextColor: rgb(157, 165, 180);
$accent1: rgba(109, 214, 155, 80%);
$accent2: #6caccb;

// Light Mode
// Content Colors
$lightMainBackground: whitesmoke;

// Light Text Colors
$lightMainTextColor: rgb(24, 24, 24);
