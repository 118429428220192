@import '../_mixins';
@import '../_variables';

// Form Header

.contact-container {
    @include flexCenter();
    flex-direction: column;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2500' height='700' preserveAspectRatio='none' viewBox='0 0 2500 700'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1058%26quot%3b)' fill='none'%3e%3crect width='2500' height='700' x='0' y='0' fill='rgba(12%2c 12%2c 12%2c 1)'%3e%3c/rect%3e%3cpath d='M1602.62 725.47C1787.19 721.57 1921.11 483.87 2296.44 472.65 2671.77 461.43 2799.19 158.37 2990.26 150.65' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1196.51 744.7C1391.59 728.14 1500.37 341.37 1860.8 329.99 2221.22 318.61 2354.99 183.86 2525.08 182.99' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M283.36 835.82C528.4 813.57 682.77 307.75 1111.44 303.91 1540.11 300.07 1525.48 391.41 1939.51 391.41 2353.55 391.41 2559.42 304.03 2767.59 303.91' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1051.15 804.02C1204.87 787.81 1176.58 454.85 1561.26 454.63 1945.93 454.41 2322.08 642.84 2581.47 643.63' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M711.43 710.22C877.25 702.96 835.59 432.05 1310.3 423.59 1785.02 415.13 2197.54 97.56 2508.05 94.59' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1058'%3e%3crect width='2500' height='700' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: bottom;
    .form-header {
        @include headerFont();
        font-size: $largeHeader;
        margin: $globalMargin;
        color: $accent1;
    }

    .open-to-work {
        @include smallTextFont();
        color: $secondaryTextColor;
        margin: $globalMargin;
        text-align: center;
        margin-top: 0;
    }

    .social-icons {
        display: flex;
        align-items: center;
        .fab {
            font-size: $largeIcon;
            margin-right: 15px;
            &:hover {
                filter: brightness(140%);
            }
        }
        .substack {
          width: 40px;
        }

        .fa-github {
            color: #d6d8da;
            margin-left: 15px;
        }

        .fa-linkedin {
            color: #0966c2;
        }

        .social-hr {
            display: inline;
            height: 1px;
            background-color: $secondaryTextColor;
            width: 20vw;
            max-width: 220px;
            border: 0;
        }
    }
}

.light-contact-background {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='2500' height='700' preserveAspectRatio='none' viewBox='0 0 2500 700'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1057%26quot%3b)' fill='none'%3e%3crect width='2500' height='700' x='0' y='0' fill='rgba(245%2c 245%2c 245%2c 1)'%3e%3c/rect%3e%3cpath d='M1602.62 725.47C1787.19 721.57 1921.11 483.87 2296.44 472.65 2671.77 461.43 2799.19 158.37 2990.26 150.65' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1196.51 744.7C1391.59 728.14 1500.37 341.37 1860.8 329.99 2221.22 318.61 2354.99 183.86 2525.08 182.99' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M283.36 835.82C528.4 813.57 682.77 307.75 1111.44 303.91 1540.11 300.07 1525.48 391.41 1939.51 391.41 2353.55 391.41 2559.42 304.03 2767.59 303.91' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M1051.15 804.02C1204.87 787.81 1176.58 454.85 1561.26 454.63 1945.93 454.41 2322.08 642.84 2581.47 643.63' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M711.43 710.22C877.25 702.96 835.59 432.05 1310.3 423.59 1785.02 415.13 2197.54 97.56 2508.05 94.59' stroke='rgba(108%2c 172%2c 203%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1057'%3e%3crect width='2500' height='700' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

//Form

#contact-form {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 500px;
    margin-top: 50px;
    .form-input,
    .text-area {
        width: 100%;
        margin: 5px 0;
        padding: 10px;
        box-sizing: border-box;
        @include smallTextFont();
        font-size: $smallText;
        color: $secondaryTextColor;
        letter-spacing: 1px;
        border: 0;
        border-radius: 5px;
        background-color: rgba(22, 22, 22, 0.9);
        &::placeholder {
            @include smallTextFont();
            font-size: $smallText;
            color: $secondaryTextColor;
            letter-spacing: 1px;
        }
        &:focus {
            outline: 1px solid $secondaryTextColor;
        }
    }

    .form-input {
        height: 40px;
    }

    .text-area {
        height: 300px;
        resize: none;
    }

    .form-border {
        border: 1px solid $lightMainTextColor;
    }
    .submit-btn {
        @include button();
        align-self: center;
        width: 50%;
        @include headerFont();
        cursor: pointer;
        margin-top: 40px;
        padding: 10px 50px;
    }
}

@media only screen and (max-width: 800px) {
    #contact-form {
        width: 90%;
        margin: 50px 0 200px 0;
        .form-input {
            height: 55px;
        }

        .form-input,
        .text-area {
            font-size: $smallHeader;
            &::placeholder {
                font-size: $smallHeader;
            }
        }
        .submit-btn {
            background-color: $accent1;
            color: #000;
        }
    }
}
@media only screen and (min-width: 800px) {
    .contact-container {
        height: 100vh;
    }
}
