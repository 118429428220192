@import '../_mixins';
@import '../variables';

#skills {
    padding-top: 75px;
    @include flexCenter();
    flex-direction: column;
    margin: $globalMargin;
}

// Skills section

.skill-section-container {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}

.skill-section {
    max-width: 450px;
    height: min-content;
    padding-left: 20px;
    margin: 80px 30px 0 0;
    border-left: 1px solid $mainTextColor;
    @include headerFont();
    color: $secondaryTextColor;
    .skill-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: $mediumHeader;
        color: $mainTextColor;
        .fas {
            font-size: $largeIcon;
            padding-right: 15px;
            color: $accent1;
        }
    }

    h5 {
        font-size: $smallHeader;
        margin: 20px 0 7px 0;
    }

    .skill-text {
        @include smallTextFont();
        font-size: $smallText;
        margin: 5px 0;
        color: $mainTextColor;
        line-height: 25px;
        list-style-type: circle;
        a {
            color: $accent2;
            display: inline-block;
            vertical-align: top;
            &:hover {
                color: $mainTextColor;
            }
        }
    }
}
// Dynamic Classes

.less-box-shadow {
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.404);
}

@media (max-width: 800px) {
    .skill-section-container {
        justify-content: flex-start;
    }
    .skill-section {
        margin: 80px 0 0 0;
    }

    .label {
        width: 100%;
    }

    .tab-text {
        margin: 30px 0 !important;
    }
}
@media (min-width: 800px) {
    .label {
        flex: 1;
    }
}
