@import '../_variables';
@import '../mixins';

// Desktop
#navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 75px;
    z-index: 4;
    @include navFont();
    color: $secondaryTextColor;
    background-color: $black;
    box-shadow: 0 5px 15px $shadowColor;
    transition: transform 0.3s ease-out;
    a {
        color: $secondaryTextColor;
        text-decoration: none;
        padding: 15px 0;
        transition: color 0.2s ease;
        &:hover {
            color: $accent2;
        }
    }

    #logo {
        height: 70px;
        width: 70px;
        padding: 10px;
    }

    .nav-links-container {
        display: flex;
        align-items: center;
        list-style-type: none;
        padding: 0;
        .nav-link {
            padding: 0 20px;
        }

        .no-border {
            border-left: 0;
        }
    }
}

.brackets {
    color: $accent1;
    font-size: 1.5rem;
    margin-left: 30px;
    margin-right: 15px;
}

// Accessibility Dropdown
.dropdown-container {
    margin: $globalMargin;
    position: relative;
    padding-bottom: 50px;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    &:hover {
        color: $accent2;
    }

    .dropdown-content {
        position: absolute;
        display: none;
        right: 0;
        top: 50px;
        height: min-content;
        width: min-content;
        white-space: nowrap;
        color: $mainTextColor;
        background-color: $mainBackground;
        border-radius: 5px;
        border: 1px solid $secondaryTextColor;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.616);

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        li {
            border-radius: 5px;
            padding: 15px;
            cursor: pointer;
            user-select: none;
            &:hover {
                background-color: rgb(43, 43, 43);
                color: $accent2;
                .fas {
                    transform: scale(1.3);
                }
            }
        }

        .fas {
            font-size: $smallIcon;
            color: $accent1;
            padding-right: 10px;
            transition: all 0.2s ease;
        }

        &:before {
            content: '';
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $secondaryTextColor;
            right: 30%;
            top: -10px;
        }
    }
}

// Mobile

.menu-bars {
    position: fixed;
    cursor: pointer;
    visibility: hidden;
    padding: 5px 10px 0 15px;
    background-color: $black;
}

.bar1,
.bar2,
.bar3 {
    background-color: $mainTextColor;
    width: 35px;
    height: 1.5px;
    margin: 10px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.change .bar1 {
    transform: rotate(-45deg) translate(-10px, 8px);
}

/* Fade out the second bar */
.change .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
}

// Dynamic Classes

// Hide mobile navbar on desktop
.mobile-nav-bar {
    display: none;
}

// Show dropdown menu in mobile and desktop
.show-menu {
    .dropdown-content {
        display: block;
    }
}

// For font-change buttons when min/max has been reached
.disabled {
    color: rgb(104, 104, 104);
}

// When accessibility type is active
.active {
    color: white;
    background-color: rgb(107, 107, 107);
}
// Hide navbar when scrolling down
.hide-scroll-down {
    transform: translateY(-100%);
}

// Hide expanding div until mobile nav appears
.border {
    display: none;
}

@media only screen and (max-width: 800px) {
    // Top navigation
    .mobile-nav-bar {
        display: block;
        position: fixed;
        height: 50px;
        width: 100%;
        background-color: $black;
        visibility: visible;
        box-shadow: 5px 5px 10px black;
        .menu-bars {
            visibility: visible;
            cursor: pointer;
            z-index: -1;
        }
    }

    .dropdown-container {
        height: min-content;
        visibility: visible;
        position: absolute;
        right: 0;
        top: -10px;
    }

    // Slide up navigation
    #navbar {
        height: 100%;
        visibility: hidden;
        z-index: 5;
        background-color: $black;
        .nav-links-container {
            flex-direction: column;
            justify-content: center;
            width: 100vw;
            font-size: 1.5rem;
            .nav-link {
                border: 0;
                padding: 50px 100%;
            }
        }

        .border {
            display: block;
            position: absolute;
            bottom: 0;
            left: 50px;
            width: 3px;
            height: 20px;
            background-color: $accent1;
            animation: grow 3s forwards;
        }

        .animation {
            @keyframes grow {
                0% {
                    height: 0px;
                    bottom: 0;
                }
                100% {
                    height: 80%;
                    bottom: 0;
                }
            }
        }
    }

    .visibility {
        visibility: visible !important;
        top: 100vh;
        transform: translateY(-100vh);
        .brackets {
            display: none;
        }

        .accessibility {
            visibility: hidden;
        }

        .dropdown-container {
            pointer-events: none;
        }

        .menu-bars {
            background-color: $mainBackground;
        }

        .mobile-nav-bar {
            visibility: hidden;
        }
    }
}
