@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@800&family=Kanit:wght@500&family=Nunito+Sans&family=Share+Tech+Mono&display=swap');
@import '_variables';
$aos-distance: 20px; // It has to be above import
@import '../../node_modules/aos/src/sass/aos.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    background-color: $mainBackground;
    font-size: 1rem;
}

body {
    margin: 0;
    min-height: 100vh;
    display: grid;
    overflow-x: hidden;
    grid-template-rows:
        [about] 100vh [skills] auto [projects] auto
        [contact footer] auto;
}

.light-global {
    background-color: $lightMainBackground !important;
}

.light-text {
    color: $lightMainTextColor !important;
}

.light-border {
    border-left: 1px solid $lightMainTextColor !important;
}

.high-contrast {
    filter: contrast(200%);
    .about-background {
        height: 8%;
    }
}

.grayscale {
    filter: grayscale(100%);
    .about-background {
        height: 8%;
    }
}

.no-border {
    border: 0;
}

.section-header {
    font-family: 'Roboto Mono', monospace;
    color: rgba(0, 0, 0, 0.103);
    font-size: 5rem;
    margin: 0;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
}
