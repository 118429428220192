@import '../_mixins';
@import '../_variables';

#footer {
    @include flexCenter();
    align-self: flex-end;
    flex-flow: wrap;
    background-color: $black;
}

.metal {
    height: 33px;
    width: 33px;
    margin: 20px;
}

#wcb {
    position: absolute;
    right: 0;
    margin: 10px 10px 0 0;
}

.footer-text {
    @include smallTextFont();
    font-size: $smallText;
    color: $secondaryTextColor;
}

@media only screen and (max-width: 800px) {
    #wcb {
        position: static;
        margin: 0;
    }
}
