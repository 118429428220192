@import '_variables';

@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin button {
    @include headerFont();
    cursor: pointer;
    text-decoration: none;
    background: transparent;
    color: $mainTextColor;
    border: 1px solid $secondaryTextColor;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.445);
    margin: 5px;
    border-radius: 25px;
    transition: all 0.1s ease-in;
    &:hover,
    &:active {
        background: $accent1;
        color: #000;
        transform: scale(1.05);
        transform: translateY(-3px);
        box-shadow: 10px 10px 12px rgba(0, 0, 0, 0.445);
    }
}

//Fonts
@mixin smallTextFont {
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: 2px;
}

@mixin headerFont {
    font-family: 'Exo', sans-serif;
    letter-spacing: 3px;
}

@mixin xlHeaderFont {
    font-family: 'Exo', sans-serif;
    letter-spacing: 10px;
    color: $accent1;
}

@mixin navFont {
    font-family: 'Exo', sans-serif;
    color: $secondaryTextColor;
    letter-spacing: 4px;
    font-weight: 800;
}
